.meq-services-highlights {
  color: #fff;
  position: absolute;
  top: 0;
  padding: 20px;
  text-rendering: optimizelegibility;
  letter-spacing: .03em;
  font-size: 0.9rem;
}
.meq-services-highlights-img {
  position: absolute;
}
.meq-services-info {
  padding: 15px 20px !important;
}
.meq-services-img {
  height: 232px !important;
}
.meq-services-img > img {
  width: 100%;
}
img.services-icon {
  border: none;
  height: 30px;
  margin-right: 10px;
  padding: 2px;
  width: 30px;
}