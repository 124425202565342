.hero-section {
  height: calc(100% - 88px);
}
.swiper-pagination {
  left: 50% !important;
}
.home-slider-pagination {
  text-align: right;
  padding-right: 4%;
  position: absolute;
}
.home-slider-pagination > .swiper-pagination-bullet {
  position: relative;
  z-index: 1;
}
.slider-heading {
  color: #fff;
}