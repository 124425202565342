section.main-section_custom,.main-services_custom, .main-team_custom{
  padding: 3rem 0 3rem 0 !important;
}

section.main-sliderServices_custom{
  padding: 3rem 0 3rem 0 !important;
}

.has-overlay-dark::before {
	background: rgba(17, 17, 17, 0.6) !important;
}

.meq-h2 {
  color: #fff !important;
}