.bg-black-1 {
	background: #f8f9fa !important
}

.bg-black-2 {
	background: #373737 !important
}

.bg-black-3 {
	background: #f8f9fa !important
}

.bg-black-4 {
	background: #373737 !important
}

.bg-black-5 {
	background: #f8f9fa !important
}

.bg-black-6 {
	background: #333 !important
}

.bg-black-2 .form-control {
	background: #2c2c2c
}

.bg-black-2 .form-control:focus {
	background: #2c2c2c !important
}

.bg-black-2 .label-template-checkbox::before {
	background: #2c2c2c
}

.bg-black-3 .form-control {
	background: #303030
}

.bg-black-3 .form-control:focus {
	background: #303030 !important
}

.bg-black-3 .label-template-checkbox::before {
	background: #303030
}

body {
	background: #f7f9fc
}

i[class*='icon-'] {
	-webkit-transform: translateY(2px);
	transform: translateY(2px)
}

a {
	-webkit-transition: all 0.4s;
	transition: all 0.4s
}

a,
button,
i,
span,
strong,
small {
	display: inline-block
}

h1 span,
h2 span,
h3 span {
	margin-right: 15px
}

h1 .badge,
h2 .badge,
h3 .badge {
	font-size: 1rem;
	font-weight: 500
}

button {
	cursor: pointer
}

section {
	padding: 8.5rem 0
}

section header {
	margin-bottom: 30px
}

blockquote.blockquote {
	background: #fafafa;
	padding: 30px
}

blockquote.blockquote p {
	font-size: 1rem;
	font-size: #555;
	line-height: 1.7rem
}

blockquote.blockquote .author {
	font-size: 1.1rem;
	margin-top: 20px;
	font-weight: 700;
	color: #f7f9fc
}

p {
	color: #999
}

#style-switch-button {
	z-index: 9999 !important
}

.text-bold {
	font-weight: 700
}

.text-small {
	font-size: 0.9rem
}

.template-text,
.listing-home p {
	font-weight: 300;
	line-height: 1.7rem;
	color: #fff;
}

.text-thin {
	font-weight: 400 !important
}

.btn {
	-webkit-transition: all 0.3s !important;
	transition: all 0.3s !important
}

.btn-outline-light:hover {
	color: #5cba3c !important
}

.no-radius {
	border-radius: 0 !important
}

.radius-small {
	border-radius: 5px !important
}

.badge {
	font-weight: 500;
	margin-right: 5px
}

.table-responsive {
	display: table
}

.badge {
	border-radius: 0;
	color: #fff !important
}

.full-width {
	width: 100% !important
}

.bootstrap-select.show>.dropdown-menu>.dropdown-menu {
	display: block;
	overflow-y: hidden !important
}

.bootstrap-select>.dropdown-menu>.dropdown-menu li.hidden {
	display: none
}

.bootstrap-select>.dropdown-menu>.dropdown-menu li a {
	display: block;
	width: 100%;
	padding: 3px 1.5rem;
	clear: both;
	font-weight: 400;
	color: #999;
	text-align: inherit;
	white-space: nowrap;
	background: 0 0;
	border: 0;
	font-weight: 300;
	outline: none
}

.bootstrap-select>.dropdown-menu>.dropdown-menu li a:hover {
	background: #eee;
	color: #f7f9fc !important
}

.bootstrap-select .dropdown-menu {
	margin: 0 !important;
	-webkit-transform: none !important;
	transform: none !important
}

.bootstrap-select .dropdown-menu::before,
.bootstrap-select .dropdown-menu::after {
	display: none !important
}

.bootstrap-select .dropdown-menu {
	border-radius: 0;
	border: 0;
	-webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1), -1px -1px 2px rgba(0, 0, 0, 0.1);
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1), -1px -1px 2px rgba(0, 0, 0, 0.1);
	-webkit-transition: -webkit-transform 0.3s;
	transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	transition: transform 0.3s, -webkit-transform 0.3s;
	margin-top: 15px;
	-webkit-transform: translateY(30px);
	transform: translateY(30px);
	color: #999 !important;
	font-size: 1em;
	min-width: 200px
}

.bootstrap-select .dropdown-menu.open {
	max-height: 230px !important;
	overflow-y: scroll !important
}

.bootstrap-select .dropdown-menu.open::-webkit-scrollbar {
	width: 5px
}

.bootstrap-select .dropdown-menu.open::-webkit-scrollbar-thumb {
	background-color: darkgrey
}

.bootstrap-select .dropdown-menu::after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #fff;
	position: absolute;
	top: -5px;
	left: 30px
}

.bootstrap-select .dropdown-menu::before {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 8px solid #eee;
	position: absolute;
	top: -8px;
	left: 30px
}

.bootstrap-select .dropdown-menu a {
	color: #999
}

.bootstrap-select .dropdown-menu a:hover {
	background: #5cba3c;
	color: #fff
}

.bootstrap-select .dropdown-menu a {
	padding: 10px 20px
}

.bootstrap-select .dropdown-toggle {
	border: none !important;
	background: #f7f9fc;
	color: #fff !important;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 1.5rem
}

.bootstrap-select .dropdown-toggle span {
	color: #999;
	font-weight: 300;
	font-size: 0.9rem;
	text-transform: none
}

.bootstrap-select .dropdown-toggle span.bs-caret {
	margin-right: -10px
}

.bootstrap-select .dropdown-toggle:focus {
	outline: none !important;
	outline-offset: 0 !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
	width: 100%
}

.no-anchor-style {
	text-decoration: none !important;
	color: inherit !important
}

.bg-gray {
	background: #f5f5f5 !important
}

.pagination {
	margin-top: 30px
}

.pagination a.page-link {
	color: #999;
	border: none;
	background: #383838;
	border-radius: 0 !important;
	margin: 0 3px
}

.pagination a.page-link.active {
	color: #fff;
	background: #5cba3c
}

.pagination a.page-link:hover {
	background: #fff;
	color: #f7f9fc
}

.nav-pills .nav-link {
	border-radius: 0;
	background: #f5f5f5;
	padding: 15px;
	text-transform: uppercase;
	font-weight: 400;
	color: #555
}

.nav-pills .nav-link.active {
	background: #5cba3c
}

.dropdown-menu {
	border-radius: 0;
	border: 0;
	font-size: 1rem;
	min-width: 200px;
	-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	background: #f7f9fc;
	color: #fff;
}

.dropdown-menu a {
	color: #fff;;
	padding: 10px 15px !important;
	font-size: 0.9rem
}

.dropdown-menu a:hover {
	background: #5cba3c;
	color: #fff
}

.dropdown-menu a:active {
	background-color: #fff
}

.dropdown-submenu {
	position: relative
}

.dropdown-submenu>.dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: -2px;
	font-size: 1rem
}

.dropdown-toggle {
	background: none;
	outline: none;
	border-radius: 0
}

.dropdown-toggle span {
	color: #555
}

.dropdown-toggle::after {
	border: none !important;
	content: '\f107';
	vertical-align: baseline;
	font-family: 'FontAwesome';
	margin-left: 0
}

.megamenu {
	width: 100%;
	padding: 40px 30px;
	margin-top: 0;
	z-index: 9999 !important
}

.megamenu strong {
	margin-bottom: 10px;
	display: block;
	color: #fff;
	font-weight: 500;
	display: block
}

.megamenu strong::after {
	content: '';
	width: 30px;
	height: 2px;
	display: block;
	background: #5cba3c
}

.megamenu ul {
	padding: 0;
	margin: 0
}

.megamenu ul a {
	padding: 5px 0 !important;
	text-decoration: none;
	color: #bdbdbd;
	font-size: 0.9em !important
}

.megamenu ul a:hover {
	background: none;
	color: #5cba3c !important
}

.menu-large {
	position: static !important
}

.has-overlay-dark {
	position: relative;
	z-index: 1
}

.has-overlay-dark::before {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	background: rgba(17, 17, 17, 0.85);
	z-index: -1
}

.has-line::after {
	content: '';
	width: 50px;
	height: 3px;
	display: block;
	margin-top: 5px;
	background: #5cba3c
}

.btn-gradient {
	background-color: #5cba3c;
	background-image: -webkit-gradient(linear, left top, right top, from(#5cba3c), to(#37dc90));
	background-image: linear-gradient(to right, #5cba3c, #37dc90);
	border-color: transparent !important;
	color: #fff;
	border: none !important
}

.btn-gradient:hover,
.btn-gradient:focus {
	color: #fff;
	opacity: 0.9
}

.sticky {
	width: 100%;
	position: fixed;
	top: 0;
	right: 0
}

.scrollUp {
	-webkit-transform: translateY(-110%);
	transform: translateY(-110%)
}

.swiper-pagination-bullet-active {
	background: #5cba3c
}

.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
	background: #666;
	width: 12px;
	height: 12px
}

.swiper-pagination-bullet-active {
	background: #5cba3c
}

.form-group {
	position: relative
}

.form-control {
	border-radius: 40px;
	border: none !important;
	background: #f7f9fc;
	color: #fff !important;
	font-weight: 300
}

.form-control::-moz-placeholder {
	font-size: 0.9rem;
	font-weight: 300;
	font-family: "Poppins", sans-serif;
	color: #999 !important
}

.form-control::-webkit-input-placeholder {
	font-size: 0.9rem;
	font-weight: 300;
	font-family: "Poppins", sans-serif;
	color: #999 !important
}

.form-control:-ms-input-placeholder {
	font-size: 0.9rem;
	font-weight: 300;
	font-family: "Poppins", sans-serif;
	color: #999 !important
}

.form-control:focus {
	background: #f7f9fc !important
}

label {
	color: #fff;;
	padding-left: 15px;
	margin-top: 15px
}

.label-template-checkbox {
	color: #999;
	font-family: "Poppins", sans-serif;
	font-weight: 300;
	position: relative;
	padding-left: 25px;
	margin-right: 20px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.label-template-checkbox::before {
	content: '';
	width: 16px;
	height: 16px;
	background: #f7f9fc;
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%)
}

.label-template-checkbox::after {
	content: '\f00c';
	width: 16px;
	height: 16px;
	background: #5cba3c;
	text-align: center;
	line-height: 16px;
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	opacity: 0;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	color: #fff;
	font-family: 'FontAwesome';
	font-size: 0.65rem
}

.label-template-checkbox input {
	display: none
}

.label-template-checkbox.active::after {
	opacity: 1
}

.placeholder-right::-moz-placeholder {
	text-align: right
}

.placeholder-right::-webkit-input-placeholder {
	text-align: right
}

.placeholder-right:-ms-input-placeholder {
	text-align: right
}

textarea {
	min-height: 150px
}

.radius-sm {
	border-radius: 10px !important
}

.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	color: #777;
	text-align: center;
	padding: 30px;
	border: 2px dashed #444;
	cursor: pointer;
	display: block;
	font-size: 0.85rem
}

.upload-btn-wrapper i {
	font-size: 1.5rem;
	margin-bottom: 10px
}

.upload-btn-wrapper input[type=file] {
	font-size: 100px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	cursor: pointer
}

.breadcrumb {
	background: none;
	padding: 0;
	font-size: 0.8rem;
	position: relative;
	padding-left: 20px;
	margin-bottom: 0
}

.breadcrumb::before {
	content: '\f015';
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	color: #5cba3c;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	font-family: 'FontAwesome'
}

.breadcrumb a {
	text-decoration: none;
	color: #999
}

.breadcrumb a:hover,
.breadcrumb a:focus {
	color: #5cba3c;
	text-decoration: none
}

hr.line {
	border-color: #f7f9fc
}

.top-bar {
	background: #383838;
	color: #fff;
	padding: 15px 0
}

.top-bar a {
	color: inherit
}

.top-bar a:hover,
.top-bar a:focus {
	color: #fff;
	text-decoration: none
}

.top-bar ul {
	margin-bottom: 0
}

.top-bar .menu-left {
	color: #cbcbcb;
	font-size: 0.8rem;
	font-weight: 300
}

.top-bar .menu-right {
	color: #fff;
	font-size: 0.75rem
}

.top-bar .menu-right li {
	margin: 0
}

.top-bar .menu-right a {
	text-transform: uppercase;
	padding: 0 20px;
	border-right: 1px solid #b2b2b2;
	font-weight: 500
}

.top-bar .menu-right a:hover,
.top-bar .menu-right a:focus {
	color: #5cba3c
}

.top-bar .menu-right i {
	margin-right: 5px
}

.search-area {
	-webkit-transform: translateX(-120%);
	transform: translateX(-120%);
	-webkit-transition: all 0.4s;
	transition: all 0.4s;
	opacity: 0;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	right: 0;
	background: rgba(42, 42, 42, 0.98);
	z-index: 9999
}

.search-area.is-visible {
	-webkit-transform: none;
	transform: none;
	opacity: 1
}

.search-inner {
	width: 100%;
	height: 100%
}

.search-inner form {
	padding: 20px;
	max-width: 700px;
	width: 100%;
	padding: 40px
}

.search-inner .form-group {
	position: relative
}

.search-inner input {
	background: none;
	border: none;
	outline: none;
	color: #fff;
	width: 100%;
	padding: 5px 0;
	border-bottom: 1px solid #999;
	font-size: 1.2rem;
	font-weight: 300
}

.search-inner input:focus {
	border-color: #5cba3c
}

.search-inner input::-moz-placeholder {
	font-family: "Poppins", sans-serif;
	font-weight: 300
}

.search-inner input::-webkit-input-placeholder {
	font-family: "Poppins", sans-serif;
	font-weight: 300
}

.search-inner input:-ms-input-placeholder {
	font-family: "Poppins", sans-serif;
	font-weight: 300
}

.search-inner .submit {
	background: none;
	color: #999;
	border: none;
	text-transform: uppercase;
	font-family: "Poppins", sans-serif;
	position: absolute;
	right: 0;
	bottom: 0;
	font-size: 1.2rem;
	-webkit-transition: all 0.3s;
	transition: all 0.3s
}

.search-inner .submit:hover {
	color: #5cba3c
}

.search-inner .close-btn {
	position: absolute;
	top: 20px;
	right: 40px;
	color: #fff;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.8rem;
	letter-spacing: 0.08rem;
	color: #fff;;
	cursor: pointer
}

.search-inner .close-btn i {
	margin-left: 5px
}

@media (max-width: 767px) {
	.top-bar .search-area form .submit {
		position: static;
		margin: 10px 0;
		width: 100%;
		color: #5cba3c;
		font-weight: 500
	}
}

nav.navbar {
	padding-top: 20px;
	padding-bottom: 20px;
	background: #f7f9fc;
	color: #fff;
	-webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.14);
	box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.14);
	z-index: 9995;
	-webkit-transition: all 0.4s;
	transition: all 0.4s
}

nav.navbar .nav-item.active {
	color: #5cba3c
}

nav.navbar .nav-link {
	color: inherit;
	text-transform: uppercase;
	font-size: 0.9rem;
	font-weight: 500
}

nav.navbar .nav-link:hover {
	color: #5cba3c
}

nav.navbar .dropdown-menu {
	margin-top: 30px
}

nav.navbar a.dropdown-item,
nav.navbar .dropdown-submenu a {
	font-weight: 400;
	text-transform: none
}

nav.navbar a.dropdown-item:hover,
nav.navbar .dropdown-submenu a:hover {
	color: #fff
}

nav.navbar .megamenu {
	margin-top: 0
}

nav.navbar .navbar-brand {
	max-width: 180px
}

nav.navbar .navbar-toggler {
	outline: none
}

nav.navbar .navbar-toggler span {
	width: 25px;
	margin: 5px;
	display: block;
	height: 2px;
	background: #fff;
	-webkit-transition: all 0.2s;
	transition: all 0.2s
}

nav.navbar .navbar-toggler.active span:first-of-type {
	-webkit-transform: rotate(45deg) translate(10px);
	transform: rotate(45deg) translate(10px)
}

nav.navbar .navbar-toggler.active span:last-of-type {
	-webkit-transform: rotate(-45deg) translate(10px);
	transform: rotate(-45deg) translate(10px)
}

nav.navbar .navbar-toggler.active span:nth-of-type(2) {
	opacity: 0
}

.modal {
	z-index: 9999
}

.modal .modal-content {
	border-radius: 0;
	background-color: #f7f9fc;
	color: #fff
}

.modal .modal-header {
	border-bottom-color: #444
}

.modal .modal-footer {
	border-top-color: #444
}

.modal-open .modal {
	background: rgba(0, 0, 0, 0.85)
}

@media (max-width: 991px) {
	nav.navbar .navbar-collapse {
		max-height: 350px;
		overflow-y: auto;
		text-align: center;
	}
	nav.navbar .dropdown-menu {
		-webkit-box-shadow: none;
		box-shadow: none;
		margin-top: 0;
		padding-left: 15px
	}
	nav.navbar .megamenu {
		padding: 10px
	}
}

.hero-section {
	padding: 0;
	color: #fff
}

.hero-section .hero-content {
	min-height: calc(100vh - 88px);
	background-size: cover !important;
	background-position: center center !important;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.hero-section .swiper-pagination-bullet,
.hero-section .swiper-pagination-bullet-active {
	background: none !important;
	font-weight: 700;
	margin: 0 15px;
	width: auto;
	height: auto;
	font-size: 1.4rem
}

.hero-section .swiper-pagination-bullet-active {
	font-size: 3rem
}

.hero-section .swiper-pagination {
	max-width: 1140px;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	left: 50%;
	text-align: right;
	padding: 20px
}

.hero-section p {
	color: #fff;
}

.hero-page {
	padding: 50px 0
}

.search-property {
	padding: 70px 0
}

.search-property .form-group {
	margin-bottom: 20px
}

.property {
	-webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.14);
	box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.14);
	background: #333333;
	position: relative;
	z-index: 1
}

.property::before {
	content: '';
	width: calc(100% + 8px);
	height: calc(100% + 8px);
	position: absolute;
	top: -4px;
	right: -4px;
	background: none;
	display: block;
	border: 4px solid transparent;
	z-index: -1;
	-webkit-transition: all 0.4s;
	transition: all 0.4s
}

.property:hover .overlay {
	opacity: 1
}

.property:hover .overlay a {
	-webkit-transform: none;
	transform: none;
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s
}

.property:hover::before {
	border-color: #5cba3c
}

.property:hover {
	border-color: #5cba3c
}

.property .image {
	max-height: 230px;
	overflow: hidden;
	position: relative
}

.property .overlay {
	width: 100%;
	height: 100%;
	background: rgba(34, 34, 34, 0.96);
	opacity: 0;
	-webkit-transition: all 0.4s;
	transition: all 0.4s;
	position: absolute;
	top: 0;
	right: 0
}

.property .overlay a {
	-webkit-transform: translateY(20px);
	transform: translateY(20px)
}

.property .info {
	padding: 30px
}

.property .tags li {
	margin: 0
}

.property .tags a {
	font-size: 0.8rem;
	color: #fff;
}

.property .tags a:hover {
	text-decoration: none;
	color: #5cba3c
}

.property .price {
	font-size: 1.4rem
}

.property .price small {
	font-size: 0.9rem
}

.property .statistics {
	background: #383838;
	padding: 20px 30px;
	color: #999
}

.property .statistics .item {
	width: 33.333%
}

.property .statistics span {
	font-size: 0.9rem
}

.property .property-footer {
	padding: 30px
}

.property .property-footer .avatar {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	overflow: hidden;
	margin-right: 10px
}

.property .property-footer .text {
	color: #999
}

.property .property-footer .text strong {
	line-height: 0.8rem;
	font-weight: 500
}

.property .property-footer .text span {
	font-size: 0.8rem
}

.property .property-footer a.favourite {
	font-size: 1.5rem;
	color: #202020 !important
}

.property .property-footer a.favourite:hover,
.property .property-footer a.favourite:focus {
	color: #5cba3c !important
}

.apartments .swiper-slide {
	padding: 5px
}

.featured-properties .image {
	max-height: 450px;
	overflow: hidden;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center
}

.featured-properties .text {
	padding: 100px 30px;
	-webkit-box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.12);
	box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.12);
	background: #333333
}

.featured-properties .text strong {
	color: #6a6a6a;
	text-transform: uppercase;
	font-size: 0.8rem;
	margin-bottom: 20px;
	letter-spacing: 0.08rem
}

.featured-properties .text p {
	color: #8f8f8f;
	line-height: 1.8rem;
	margin-top: 30px
}

.featured-properties .text .price {
	color: #5cba3c
}

.featured-properties .text .price strong {
	font-size: 1.4rem;
	color: inherit;
	margin-right: 5px
}

.listing-home {
	position: relative;
	height: 300px;
	overflow: hidden;
	margin-bottom: 30px
}

.listing-home:hover img {
	-webkit-transform: scale(1.15);
	transform: scale(1.15)
}

.listing-home img {
	-webkit-transition: all 0.7s;
	transition: all 0.7s;
	width: 120%
}

.listing-home h3 {
	margin-bottom: 0
}

.listing-home p {
	margin-bottom: 0
}

.listing-home .text {
	padding: 15px 30px;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	background-image: -webkit-linear-gradient(94deg, #121212 0%, rgba(18, 18, 18, 0) 80%, rgba(18, 18, 18, 0) 100%)
}

.listing-home .ribbon {
	padding: 10px;
	background: #5cba3c;
	color: #fff;
	position: absolute;
	top: 0;
	right: 0
}

.listing-home .ribbon strong {
	font-size: 2rem;
	margin-top: 6px;
	line-height: 1rem
}

.about .image {
	height: 450px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.12);
	box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.12)
}

.about .image img {
	height: 100%
}

.about .text {
	padding: 30px;
	-webkit-box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.12);
	box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.12);
	background: #333333;
	height: 100%
}

.about .text strong {
	color: #6a6a6a;
	text-transform: uppercase;
	font-size: 0.8rem;
	margin-bottom: 20px;
	letter-spacing: 0.08rem
}

.about .text p {
	color: #8f8f8f;
	line-height: 1.8rem;
	margin-top: 15px
}

.about .text .price {
	color: #5cba3c
}

.about .text .price strong {
	font-size: 1.4rem;
	color: inherit;
	margin-right: 5px
}

.agent {
	-webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.14);
	box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.14);
	background: #333333;
	position: relative;
	z-index: 1
}

.agent .image {
	height: 250px;
	overflow: hidden;
	position: relative
}

.agent::before {
	content: '';
	width: calc(100% + 8px);
	height: calc(100% + 8px);
	position: absolute;
	top: -4px;
	right: -4px;
	background: none;
	display: block;
	border: 4px solid transparent;
	z-index: -1;
	-webkit-transition: all 0.4s;
	transition: all 0.4s
}

.agent:hover::before {
	border-color: #5cba3c
}

.agent:hover .contact-info {
	opacity: 1;
	-webkit-transform: none;
	transform: none
}

.agent .info {
	padding: 30px
}

.agent .info p {
	color: #fff;;
	font-weight: 300;
	font-size: 0.9rem
}

.agent .contact-info {
	/* background: rgba(89, 137, 229, 0.95); */
	background: rgba(92, 186, 60, 0.95);
	padding: 20px 30px;
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100%;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	opacity: 0;
	-webkit-transition: all 0.5s;
	transition: all 0.5s
}

.agent .contact-info li {
	padding: 5px 0
}

.agent .contact-info a {
	font-size: 0.9rem;
	color: #fff;
	font-weight: 300
}

.agent .contact-info a i {
	width: 15px;
	height: 15px;
	line-height: 15px;
	/* background: #fff; */
	/* color: #5cba3c; */
	text-align: center;
	/* border: 1px solid #fff; */
	margin-right: 10px;
	border-radius: 50%;
	font-size: 0.75rem;
	-webkit-transition: all 0.3s;
	transition: all 0.3s
}

.agent .contact-info a:hover {
	text-decoration: none;
	color: #fff
}

.agent .agent-social {
	padding: 20px 30px;
	background: #383838
}

.agent .agent-social a {
	color: #999
}

.agent .agent-social a:hover,
.agent .agent-social a:focus {
	color: #5cba3c;
	text-decoration: none
}

.agents-listing .agent {
	margin-bottom: 30px
}

.agents-listing .image {
	height: 220px
}

.agents-listing .contact-info {
	padding: 20px
}

.agents-listing .contact-info a {
	font-size: 0.85rem
}

.testimonial {
	background: #333333;
	-webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
	color: #aaaaaa;
	max-width: 700px;
	margin: 0 auto;
	padding: 50px
}

.testimonial .feedback {
	line-height: 1.9rem;
	font-size: 1rem;
	font-weight: 300;
	text-align: center;
	position: relative;
	z-index: 1
}

.testimonial .feedback::before {
	content: '';
	width: 200px;
	height: 200px;
	background: url(/img/quote.svg);
	background-size: 100%;
	position: absolute;
	top: calc(50% - 100px);
	left: calc(50% - 100px);
	display: block;
	z-index: -1
}

.testimonial .client {
	text-align: right
}

.testimonial .client span {
	font-size: 0.9rem;
	font-weight: 300
}

.clients {
	padding: 50px 0
}

.clients .client {
	height: 120px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.clients .client img {
	max-width: 90px;
	margin: 0 auto
}

footer.footer {
	padding: 85px 0 0;
}

footer.footer p {
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.5rem;
	font-weight: 300
}

footer.footer .logo {
	margin-bottom: 20px
}

footer.footer a {
	color: #fff;
	font-size: 0.9rem
}

footer.footer a:hover,
footer.footer a:focus {
	color: #5cba3c;
	text-decoration: none
}

footer.footer .social a {
	color: #fff;
	font-size: 0.9rem
}

footer.footer .social a:hover,
footer.footer .social a:focus {
	color: #5cba3c;
	text-decoration: none
}

footer.footer ul a {
	margin-bottom: 5px
}

footer.footer h3 {
	margin-top: 10px;
	margin-bottom: 30px
}

footer.footer .contact-form .form-group {
	position: relative
}

footer.footer .contact-form .submit {
	position: absolute;
	right: 0;
	bottom: 0;
	padding-top: 11px;
	padding-bottom: 11px
}

footer.footer .copyrights {
	padding: 15px 0
}

footer.footer .copyrights p {
	margin: 0
}

footer.footer .copyrights a {
	color: #5cba3c
}

#meqEmailLayer {
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	color: #fff;
	position: fixed;
	right: 10px;
	top: 50%;
	background-color: #5cba3c;
	background-image: -webkit-gradient(linear, left top, right top, from(#5cba3c), to(#37dc90));
	background-image: linear-gradient(to right, #5cba3c, #37dc90);
	z-index: 9995;
	cursor: pointer;
	display: none;
	border-radius: 40px
}

#meqEmailLayer::before {
	content: 'info@moneyequations.com';
	width: 200px;
	height: 40px;
	background-color: #5cba3c;
	background-image: -webkit-gradient(linear, left top, right top, from(#5cba3c), to(#37dc90));
	background-image: linear-gradient(to right, #5cba3c, #37dc90);
	color: #fff;
	text-align: center;
	line-height: 40px;
	cursor: pointer;
	font-size: 0.75rem;
	padding-right: 30px;
	position: absolute;
	top: 0;
	right: 0;
	display: block;
	-webkit-transition: all 0.1s;
	transition: all 0.3s;
	-webkit-transform: translateX(40px);
	transform: translateX(40px);
	-webkit-transform-origin: top right;
	transform-origin: top right;
	border-radius: 40px;
	z-index: -1;
	opacity: 0
}

#meqEmailLayer:hover::before {
	-webkit-transform: none;
	transform: none;
	opacity: 1
}

#meqPhoneLayer {
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	color: #fff;
	position: fixed;
	left: 10px;
	top: 50%;
	background-color: #5cba3c;
	background-image: -webkit-gradient(linear, left top, right top, from(#5cba3c), to(#37dc90));
	background-image: linear-gradient(to right, #5cba3c, #37dc90);
	z-index: 9995;
	cursor: pointer;
	display: none;
	border-radius: 40px
}

#meqPhoneLayer::before {
	content: '+1 571-310-4050';
	width: 165px;
	height: 40px;
	background-color: #5cba3c;
	background-image: -webkit-gradient(linear, left top, right top, from(#5cba3c), to(#37dc90));
	background-image: linear-gradient(to right, #5cba3c, #37dc90);
	color: #fff;
	text-align: center;
	line-height: 40px;
	cursor: pointer;
	font-size: 0.75rem;
	padding-left: 10px;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	-webkit-transition: all 0.1s;
	transition: all 0.3s;
	-webkit-transform: translateX(-40px);
	transform: translateX(-40px);
	-webkit-transform-origin: top right;
	transform-origin: top right;
	border-radius: 40px;
	z-index: -1;
	opacity: 0
}

#meqPhoneLayer:hover::before {
	-webkit-transform: none;
	transform: none;
	opacity: 1
}

@media (max-width: 767px) {
	#meqEmailLayer, #meqPhoneLayer {
		display: none !important;
	}
}

.filter {
	padding: 10px 30px;
	margin-top: 50px;
	background: #f7f9fc
}

.filter strong {
	min-width: 50px;
	color: #999
}

.filter .sort {
	padding: 10px 0
}

.filter .view {
	padding: 10px 0
}

.filter .view li {
	margin: 0
}

.filter .view a {
	width: 35px;
	height: 35px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-decoration: none
}

.filter .view a:hover {
	background: #383838;
	color: #5cba3c
}

.filter .view a.active {
	background: #f7f9fc
}

.filter .bootstrap-select .dropdown-toggle {
	background: #f7f9fc;
	padding: 10px 20px;
	padding-right: 40px
}

.filter .bootstrap-select .dropdown-toggle span {
	font-size: 0.85rem;
	line-height: 1.5rem
}

.filter .bootstrap-select .dropdown-toggle span.bs-caret {
	margin-right: 10px
}

.property-listing-item {
	margin-top: 40px
}

.property-listing-item .image {
	height: 220px;
	overflow: hidden;
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center
}

.property-listing-item .image img {
	width: 100%
}

.property-listing-item .image .price {
	position: absolute;
	right: 0;
	bottom: 0;
	background: #5cba3c;
	padding: 5px 20px;
	color: #fff
}

.property-listing-item.list-full-width .image {
	height: 320px
}

.property-listing-item.list-full-width .image img {
	width: 100%
}

.property-listing-item .info {
	padding: 20px;
	padding-bottom: 0;
	background: #383838
}

.property-listing-item .info::after {
	content: '';
	background: #555;
	width: 100%;
	height: 1px;
	display: block;
	margin-top: 20px
}

.property-listing-item .info a {
	display: block
}

.property-listing-item .info .badge {
	margin-bottom: 10px
}

.property-listing-item .info p {
	font-size: 0.95rem;
	color: #eee
}

.property-listing-item .info .address {
	font-size: 0.85rem;
	font-weight: 300;
	color: #999
}

.property-listing-item .footer {
	padding: 20px;
	background: #383838;
	color: #999;
	font-size: 0.85rem
}

.property-listing-item ul i {
	color: #fff;
	margin-right: 5px
}

.property-listing-item .inner {
	height: 100%;
	padding: 10px
}

.property-listing-footer {
	font-size: 0.9rem
}

.widget {
	background: #f7f9fc;
	margin-top: 40px;
	padding: 20px
}

.widget .widget-header {
	color: #fff;
	margin-bottom: 40px
}

.sidebar-search .form-control {
	background: #f7f9fc
}

.sidebar-search .bootstrap-select .dropdown-toggle {
	background: #f7f9fc
}

.property-thumb {
	margin-bottom: 20px
}

.property-thumb .image {
	width: 85px;
	height: 85px;
	margin-right: 15px
}

.property-thumb a {
	color: #fff !important;
	font-weight: 500;
	font-size: 0.95rem
}

.property-thumb p {
	font-size: 0.8rem;
	margin-bottom: 0
}

.property-thumb .price {
	color: #5cba3c;
	font-weight: 600;
	font-size: 0.9rem
}

.location-widget ul {
	margin-right: 40px
}

.location-widget ul a {
	color: #999;
	font-size: 0.9rem;
	margin-bottom: 5px;
	text-decoration: none
}

.location-widget ul a:hover {
	color: #5cba3c
}

.map {
	position: relative
}

.map-holder {
	height: 500px;
	overflow: hidden
}

.map-holder::after {
	content: '';
	width: 100%;
	height: 30px;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-box-shadow: inset 0 10px 30px rgba(0, 0, 0, 0.2);
	box-shadow: inset 0 10px 30px rgba(0, 0, 0, 0.2)
}

.collapse-map {
	padding: 10px 30px;
	background: #383838;
	text-decoration: none !important;
	color: #eee !important;
	border-radius: 30px;
	-webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 999;
	font-size: 0.8rem
}

.toggle-features {
	margin-bottom: 15px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-left: 15px
}

.toggle-features:hover,
.toggle-features:focus {
	color: #5cba3c;
	text-decoration: none
}

.features {
	margin-left: 15px
}

.property-single .swiper-container {
	width: 100%;
	height: 545px !important;
	margin-left: auto;
	margin-right: auto
}

.property-single .swiper-slide {
	background-size: cover;
	background-position: center
}

.property-single .gallery-top {
	height: 80%;
	width: 100%
}

.property-single .gallery-thumbs {
	height: 150px !important;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 10px 0
}

.property-single .gallery-thumbs .swiper-wrapper {
	margin-left: -33.333%
}

.property-single .gallery-thumbs .swiper-slide {
	width: 33.33% !important;
	height: 100%;
	opacity: 0.4
}

.property-single .gallery-thumbs .swiper-slide-active {
	opacity: 1
}

.property-single-author {
	padding: 50px 30px
}

.property-single-author .avatar {
	min-width: 80px;
	min-height: 80px;
	max-width: 80px;
	max-height: 80px;
	border-radius: 50%;
	overflow: hidden;
	margin-right: 20px
}

.property-single-author .text {
	color: #fff
}

.property-single-author .text strong {
	font-size: 1.2rem;
	display: block;
	line-height: 1
}

.property-single-author .text span {
	color: #999;
	font-size: 0.85rem
}

.property-single-author .agent-social {
	margin-bottom: 0;
	margin-top: 10px;
	font-size: 0.8rem
}

.property-single-author .agent-social a {
	color: #fff
}

.property-single-author .agent-social a:hover {
	color: #5cba3c
}

.property-single-author .contact-info {
	font-size: 0.85rem;
	padding: 20px 0
}

.property-single-author .contact-info a {
	text-decoration: none;
	font-weight: 300;
	color: #999;
	margin-right: 5px
}

.property-single-author .contact-info i {
	margin-right: 10px
}

.block {
	padding: 30px
}

.block h3 {
	margin-top: 15px;
	margin-bottom: 30px
}

.block p {
	font-size: 0.9rem;
	font-weight: 300;
	line-height: 1.7rem
}

.property-single-details strong {
	color: #fff;
	font-size: 0.9rem;
	display: block;
	line-height: 1;
	font-weight: 500
}

.property-single-details span {
	color: #999;
	font-size: 0.85rem;
	margin-bottom: 15px
}

.property-single-features .label-template-checkbox {
	font-size: 0.9rem;
	margin-bottom: 15px;
	cursor: default
}

.floor-plan .image {
	padding: 30px;
	margin-top: 40px
}

.floor-plan .image img {
	max-width: 600px;
	width: 100%
}

.floor-plan .nav-tabs {
	border-bottom: none
}

.floor-plan a.nav-link {
	border: none;
	border-radius: 50px;
	font-size: 0.85rem;
	padding: 5px 20px;
	background: #383838;
	margin: 5px;
	color: #999
}

.floor-plan a.nav-link.active {
	background: #5cba3c;
	color: #fff
}

#property-single-map {
	width: 100%;
	height: 400px
}

.agent-full .image {
	max-height: 500px;
	overflow: hidden
}

@media (max-width: 767px) {
	.agent-full .image {
		max-height: 285px;
	}
}

.agent-full .text {
	height: 100%
}

.agent-full .header {
	padding: 30px;
	background: #5cba3c;
	color: #fff
}

.agent-full .header h2 {
	margin-bottom: 0
}

.agent-full .header p {
	color: #fff;
	font-weight: 300;
	font-size: 0.9rem
}

.agent-full .header a {
	color: #fff;
	font-size: 0.85rem;
	text-decoration: none;
	font-weight: 300
}

.agent-full .header a:hover {
	color: #fff;
	text-decoration: none;
	font-weight: 300
}

.agent-full .header i {
	margin-right: 5px
}

.agent-full .body {
	padding: 30px;
	font-size: 0.9rem
}

.agent-full .footer {
	color: #999;
	padding: 30px;
	padding-top: 0
}

.agent-full .footer .icon {
	font-size: 2.1rem;
	margin-right: 20px;
	color: #fff
}

.agent-full .footer i[class*='icon-'] {
	-webkit-transform: translateY(2px);
	transform: translateY(2px);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.agent-full .footer strong {
	display: block;
	line-height: 1;
	font-size: 1.8rem;
	color: #5cba3c
}

.agent-full .footer span {
	font-size: 0.9rem;
	font-weight: 300
}

.agent-contact-form textarea {
	min-height: 200px
}

.grid-gallery figcaption {
	background: #424242
}

.slideshow-open figure {
	background: #383838;
	border-color: #383838
}

.slideshow-open figcaption {
	background: none !important
}

.slideshow-open li::after {
	background: rgba(56, 56, 56, 0.8)
}

.customer-login h2 {
	margin-bottom: 30px
}

.customer-login h4 {
	color: #eee
}

.customer-login p {
	font-size: 0.9rem;
	font-weight: 300;
	margin: 30px 0;
	line-height: 1.7rem
}

.customer-login hr {
	margin: 30px 0;
	border-color: #555
}

.customer-login input {
	padding-top: 15px;
	padding-bottom: 15px
}

.about-brief img {
	-webkit-box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.12);
	box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.12)
}

.testimonial.bg-darker {
	background: #2e2e2e
}

.testimonial.bg-darker .feedback::before {
	opacity: 0.5
}

.error-page {
	padding: 80px 0
}

.error-page-holder h1 {
	font-size: 6rem
}

.error-page-holder .error-page-inner {
	width: 100%
}

.error-page-holder img {
	max-width: 600px;
	display: block;
	margin: 0 auto
}

/* #style-switch-button {
	position: fixed;
	top: 120px;
	left: 0px;
	border-radius: 0;
	z-index: 2
} */
#style-switch-button {
	position: fixed;
	top: 100px;
	right: 0px;
	border-radius: 0;
	z-index: 2;
}

#style-switch {
	width: 300px;
	padding: 20px;
	position: fixed;
	top: 160px;
	left: 0;
	background: #252525;
	border: solid 1px #ced4da;
	z-index: 2000
}

#style-switch h4 {
	color: #999
}

.navbar {
	padding: .5rem 1rem
}

.navbar-brand {
	display: inline-block;
	padding-top: .3125rem;
	padding-bottom: .3125rem;
	margin-right: 1rem;
	font-size: 1.25rem
}

.navbar-toggler {
	padding: .25rem .75rem;
	margin: 0;
	font-size: 1.25rem;
	line-height: 1;
	border: 1px solid transparent;
	border-radius: 50px
}

.navbar-light .navbar-brand {
	color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
	color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.5)
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
	color: rgba(0, 0, 0, 0.7)
}

.navbar-light .navbar-nav .nav-link.disabled {
	color: rgba(0, 0, 0, 0.3)
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
	color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-toggler {
	color: rgba(0, 0, 0, 0.5);
	border-color: rgba(0, 0, 0, 0.1)
}

.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0,0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-light .navbar-text {
	color: rgba(0, 0, 0, 0.5)
}

.navbar-dark .navbar-brand {
	color: #fff
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
	color: #fff
}

.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, 0.5)
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
	color: rgba(255, 255, 255, 0.75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
	color: rgba(255, 255, 255, 0.25)
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
	color: #fff
}

.navbar-dark .navbar-toggler {
	color: rgba(255, 255, 255, 0.5);
	border-color: rgba(255, 255, 255, 0.1)
}

.navbar-dark .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-dark .navbar-text {
	color: rgba(255, 255, 255, 0.5)
}

.btn {
	font-weight: 500;
	border: 1px solid transparent;
	text-transform: uppercase;
	padding: .625rem 1.5rem;
	font-size: .8rem;
	line-height: 1.5;
	border-radius: 50px;
	-webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out
}

.btn:focus,
.btn.focus {
	outline: 0;
	-webkit-box-shadow: 0 0 0 .2rem rgba(89, 137, 229, 0.25);
	box-shadow: 0 0 0 .2rem rgba(89, 137, 229, 0.25)
}

.btn.disabled,
.btn:disabled {
	opacity: .65
}

.btn:not([disabled]):not(.disabled):active,
.btn:not([disabled]):not(.disabled).active {
	background-image: none
}

.btn-primary {
	color: color-yiq(#5cba3c);
	background-color: #5cba3c;
	border-color: #5cba3c
}

.btn-primary:hover {
	color: color-yiq(#5cba3c);
	background-color: #5cba3c;
	border-color: #5cba3c
}

.btn-primary:focus,
.btn-primary.focus {
	-webkit-box-shadow: 0 0 0 .2rem rgba(92, 186, 60, 0.5);
	box-shadow: 0 0 0 .2rem rgba(92, 186, 60, 0.5)
}

.btn-primary.disabled,
.btn-primary:disabled {
	background-color: #5cba3c;
	border-color: #5cba3c
}

.btn-primary:not([disabled]):not(.disabled):active,
.btn-primary:not([disabled]):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
	color: color-yiq(#5cba3c);
	background-color: #5cba3c;
	border-color: #5cba3c;
	-webkit-box-shadow: 0 0 0 .2rem rgba(92, 186, 60, 0.5);
	box-shadow: 0 0 0 .2rem rgba(92, 186, 60, 0.5)
}

.btn-secondary {
	color: color-yiq(#868e96);
	background-color: #868e96;
	border-color: #868e96
}

.btn-secondary:hover {
	color: color-yiq(#727b84);
	background-color: #727b84;
	border-color: #6c757d
}

.btn-secondary:focus,
.btn-secondary.focus {
	-webkit-box-shadow: 0 0 0 .2rem rgba(134, 142, 150, 0.5);
	box-shadow: 0 0 0 .2rem rgba(134, 142, 150, 0.5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
	background-color: #868e96;
	border-color: #868e96
}

.btn-secondary:not([disabled]):not(.disabled):active,
.btn-secondary:not([disabled]):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
	color: color-yiq(#6c757d);
	background-color: #6c757d;
	border-color: #666e76;
	-webkit-box-shadow: 0 0 0 .2rem rgba(134, 142, 150, 0.5);
	box-shadow: 0 0 0 .2rem rgba(134, 142, 150, 0.5)
}

.btn-success {
	color: color-yiq(#28a745);
	background-color: #28a745;
	border-color: #28a745
}

.btn-success:hover {
	color: color-yiq(#218838);
	background-color: #218838;
	border-color: #1e7e34
}

.btn-success:focus,
.btn-success.focus {
	-webkit-box-shadow: 0 0 0 .2rem rgba(40, 167, 69, 0.5);
	box-shadow: 0 0 0 .2rem rgba(40, 167, 69, 0.5)
}

.btn-success.disabled,
.btn-success:disabled {
	background-color: #28a745;
	border-color: #28a745
}

.btn-success:not([disabled]):not(.disabled):active,
.btn-success:not([disabled]):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
	color: color-yiq(#1e7e34);
	background-color: #1e7e34;
	border-color: #1c7430;
	-webkit-box-shadow: 0 0 0 .2rem rgba(40, 167, 69, 0.5);
	box-shadow: 0 0 0 .2rem rgba(40, 167, 69, 0.5)
}

.btn-info {
	color: color-yiq(#17a2b8);
	background-color: #17a2b8;
	border-color: #17a2b8
}

.btn-info:hover {
	color: color-yiq(#138496);
	background-color: #138496;
	border-color: #117a8b
}

.btn-info:focus,
.btn-info.focus {
	-webkit-box-shadow: 0 0 0 .2rem rgba(23, 162, 184, 0.5);
	box-shadow: 0 0 0 .2rem rgba(23, 162, 184, 0.5)
}

.btn-info.disabled,
.btn-info:disabled {
	background-color: #17a2b8;
	border-color: #17a2b8
}

.btn-info:not([disabled]):not(.disabled):active,
.btn-info:not([disabled]):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
	color: color-yiq(#117a8b);
	background-color: #117a8b;
	border-color: #10707f;
	-webkit-box-shadow: 0 0 0 .2rem rgba(23, 162, 184, 0.5);
	box-shadow: 0 0 0 .2rem rgba(23, 162, 184, 0.5)
}

.btn-warning {
	color: color-yiq(#ffc107);
	background-color: #ffc107;
	border-color: #ffc107
}

.btn-warning:hover {
	color: color-yiq(#e0a800);
	background-color: #e0a800;
	border-color: #d39e00
}

.btn-warning:focus,
.btn-warning.focus {
	-webkit-box-shadow: 0 0 0 .2rem rgba(255, 193, 7, 0.5);
	box-shadow: 0 0 0 .2rem rgba(255, 193, 7, 0.5)
}

.btn-warning.disabled,
.btn-warning:disabled {
	background-color: #ffc107;
	border-color: #ffc107
}

.btn-warning:not([disabled]):not(.disabled):active,
.btn-warning:not([disabled]):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
	color: color-yiq(#d39e00);
	background-color: #d39e00;
	border-color: #c69500;
	-webkit-box-shadow: 0 0 0 .2rem rgba(255, 193, 7, 0.5);
	box-shadow: 0 0 0 .2rem rgba(255, 193, 7, 0.5)
}

.btn-danger {
	color: color-yiq(#dc3545);
	background-color: #dc3545;
	border-color: #dc3545
}

.btn-danger:hover {
	color: color-yiq(#c82333);
	background-color: #c82333;
	border-color: #bd2130
}

.btn-danger:focus,
.btn-danger.focus {
	-webkit-box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.5);
	box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.5)
}

.btn-danger.disabled,
.btn-danger:disabled {
	background-color: #dc3545;
	border-color: #dc3545
}

.btn-danger:not([disabled]):not(.disabled):active,
.btn-danger:not([disabled]):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
	color: color-yiq(#bd2130);
	background-color: #bd2130;
	border-color: #b21f2d;
	-webkit-box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.5);
	box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.5)
}

.btn-light {
	color: color-yiq(#f8f9fa);
	background-color: #f8f9fa;
	border-color: #f8f9fa
}

.btn-light:hover {
	color: color-yiq(#e2e6ea);
	background-color: #e2e6ea;
	border-color: #dae0e5
}

.btn-light:focus,
.btn-light.focus {
	-webkit-box-shadow: 0 0 0 .2rem rgba(248, 249, 250, 0.5);
	box-shadow: 0 0 0 .2rem rgba(248, 249, 250, 0.5)
}

.btn-light.disabled,
.btn-light:disabled {
	background-color: #f8f9fa;
	border-color: #f8f9fa
}

.btn-light:not([disabled]):not(.disabled):active,
.btn-light:not([disabled]):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
	color: color-yiq(#dae0e5);
	background-color: #dae0e5;
	border-color: #d3d9df;
	-webkit-box-shadow: 0 0 0 .2rem rgba(248, 249, 250, 0.5);
	box-shadow: 0 0 0 .2rem rgba(248, 249, 250, 0.5)
}

.btn-dark {
	color: color-yiq(#343a40);
	background-color: #343a40;
	border-color: #343a40
}

.btn-dark:hover {
	color: color-yiq(#23272b);
	background-color: #23272b;
	border-color: #1d2124
}

.btn-dark:focus,
.btn-dark.focus {
	-webkit-box-shadow: 0 0 0 .2rem rgba(52, 58, 64, 0.5);
	box-shadow: 0 0 0 .2rem rgba(52, 58, 64, 0.5)
}

.btn-dark.disabled,
.btn-dark:disabled {
	background-color: #343a40;
	border-color: #343a40
}

.btn-dark:not([disabled]):not(.disabled):active,
.btn-dark:not([disabled]):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
	color: color-yiq(#1d2124);
	background-color: #1d2124;
	border-color: #171a1d;
	-webkit-box-shadow: 0 0 0 .2rem rgba(52, 58, 64, 0.5);
	box-shadow: 0 0 0 .2rem rgba(52, 58, 64, 0.5)
}

.btn-outline-primary {
	color: #5cba3c;
	background-color: transparent;
	background-image: none;
	border-color: #5cba3c
}

.btn-outline-primary:hover {
	color: #fff;
	background-color: #5cba3c;
	border-color: #5cba3c
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
	-webkit-box-shadow: 0 0 0 .2rem rgba(89, 137, 229, 0.5);
	box-shadow: 0 0 0 .2rem rgba(89, 137, 229, 0.5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
	color: #5cba3c;
	background-color: transparent
}

.btn-outline-primary:not([disabled]):not(.disabled):active,
.btn-outline-primary:not([disabled]):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
	color: #fff;
	background-color: #5cba3c;
	border-color: #5cba3c;
	-webkit-box-shadow: 0 0 0 .2rem rgba(89, 137, 229, 0.5);
	box-shadow: 0 0 0 .2rem rgba(89, 137, 229, 0.5)
}

.btn-outline-secondary {
	color: #868e96;
	background-color: transparent;
	background-image: none;
	border-color: #868e96
}

.btn-outline-secondary:hover {
	color: #fff;
	background-color: #868e96;
	border-color: #868e96
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
	-webkit-box-shadow: 0 0 0 .2rem rgba(134, 142, 150, 0.5);
	box-shadow: 0 0 0 .2rem rgba(134, 142, 150, 0.5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
	color: #868e96;
	background-color: transparent
}

.btn-outline-secondary:not([disabled]):not(.disabled):active,
.btn-outline-secondary:not([disabled]):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
	color: #fff;
	background-color: #868e96;
	border-color: #868e96;
	-webkit-box-shadow: 0 0 0 .2rem rgba(134, 142, 150, 0.5);
	box-shadow: 0 0 0 .2rem rgba(134, 142, 150, 0.5)
}

.btn-outline-success {
	color: #28a745;
	background-color: transparent;
	background-image: none;
	border-color: #28a745
}

.btn-outline-success:hover {
	color: #fff;
	background-color: #28a745;
	border-color: #28a745
}

.btn-outline-success:focus,
.btn-outline-success.focus {
	-webkit-box-shadow: 0 0 0 .2rem rgba(40, 167, 69, 0.5);
	box-shadow: 0 0 0 .2rem rgba(40, 167, 69, 0.5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
	color: #28a745;
	background-color: transparent
}

.btn-outline-success:not([disabled]):not(.disabled):active,
.btn-outline-success:not([disabled]):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
	color: #fff;
	background-color: #28a745;
	border-color: #28a745;
	-webkit-box-shadow: 0 0 0 .2rem rgba(40, 167, 69, 0.5);
	box-shadow: 0 0 0 .2rem rgba(40, 167, 69, 0.5)
}

.btn-outline-info {
	color: #17a2b8;
	background-color: transparent;
	background-image: none;
	border-color: #17a2b8
}

.btn-outline-info:hover {
	color: #fff;
	background-color: #17a2b8;
	border-color: #17a2b8
}

.btn-outline-info:focus,
.btn-outline-info.focus {
	-webkit-box-shadow: 0 0 0 .2rem rgba(23, 162, 184, 0.5);
	box-shadow: 0 0 0 .2rem rgba(23, 162, 184, 0.5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
	color: #17a2b8;
	background-color: transparent
}

.btn-outline-info:not([disabled]):not(.disabled):active,
.btn-outline-info:not([disabled]):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
	color: #fff;
	background-color: #17a2b8;
	border-color: #17a2b8;
	-webkit-box-shadow: 0 0 0 .2rem rgba(23, 162, 184, 0.5);
	box-shadow: 0 0 0 .2rem rgba(23, 162, 184, 0.5)
}

.btn-outline-warning {
	color: #ffc107;
	background-color: transparent;
	background-image: none;
	border-color: #ffc107
}

.btn-outline-warning:hover {
	color: #fff;
	background-color: #ffc107;
	border-color: #ffc107
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
	-webkit-box-shadow: 0 0 0 .2rem rgba(255, 193, 7, 0.5);
	box-shadow: 0 0 0 .2rem rgba(255, 193, 7, 0.5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
	color: #ffc107;
	background-color: transparent
}

.btn-outline-warning:not([disabled]):not(.disabled):active,
.btn-outline-warning:not([disabled]):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
	color: #fff;
	background-color: #ffc107;
	border-color: #ffc107;
	-webkit-box-shadow: 0 0 0 .2rem rgba(255, 193, 7, 0.5);
	box-shadow: 0 0 0 .2rem rgba(255, 193, 7, 0.5)
}

.btn-outline-danger {
	color: #dc3545;
	background-color: transparent;
	background-image: none;
	border-color: #dc3545
}

.btn-outline-danger:hover {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
	-webkit-box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.5);
	box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
	color: #dc3545;
	background-color: transparent
}

.btn-outline-danger:not([disabled]):not(.disabled):active,
.btn-outline-danger:not([disabled]):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545;
	-webkit-box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.5);
	box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.5)
}

.btn-outline-light {
	color: #f8f9fa;
	background-color: transparent;
	background-image: none;
	border-color: #f8f9fa
}

.btn-outline-light:hover {
	color: #fff;
	background-color: #f8f9fa;
	border-color: #f8f9fa
}

.btn-outline-light:focus,
.btn-outline-light.focus {
	-webkit-box-shadow: 0 0 0 .2rem rgba(248, 249, 250, 0.5);
	box-shadow: 0 0 0 .2rem rgba(248, 249, 250, 0.5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
	color: #f8f9fa;
	background-color: transparent
}

.btn-outline-light:not([disabled]):not(.disabled):active,
.btn-outline-light:not([disabled]):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
	color: #fff;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
	-webkit-box-shadow: 0 0 0 .2rem rgba(248, 249, 250, 0.5);
	box-shadow: 0 0 0 .2rem rgba(248, 249, 250, 0.5)
}

.btn-outline-dark {
	color: #343a40;
	background-color: transparent;
	background-image: none;
	border-color: #343a40
}

.btn-outline-dark:hover {
	color: #fff;
	background-color: #343a40;
	border-color: #343a40
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
	-webkit-box-shadow: 0 0 0 .2rem rgba(52, 58, 64, 0.5);
	box-shadow: 0 0 0 .2rem rgba(52, 58, 64, 0.5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
	color: #343a40;
	background-color: transparent
}

.btn-outline-dark:not([disabled]):not(.disabled):active,
.btn-outline-dark:not([disabled]):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
	color: #fff;
	background-color: #343a40;
	border-color: #343a40;
	-webkit-box-shadow: 0 0 0 .2rem rgba(52, 58, 64, 0.5);
	box-shadow: 0 0 0 .2rem rgba(52, 58, 64, 0.5)
}

.btn-lg {
	padding: .8rem 1.8rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 50px
}

.btn-sm {
	padding: .4rem 1.2rem;
	font-size: .7rem;
	line-height: 1.5;
	border-radius: 50px
}

body {
	font-family: "Poppins", sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff
}

a {
	color: #5cba3c;
	text-decoration: none
}

a:focus,
a:hover {
	color: #5cba3c;
	text-decoration: underline
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-bottom: .5rem;
	font-family: inherit;
	font-weight: 700;
	line-height: 1.2;
	color: #5cba3c;
}

h1,
.h1 {
	font-size: 2.5rem
}

h2,
.h2 {
	font-size: 2rem
}

h3,
.h3 {
	font-size: 1.4rem
}

h4,
.h4 {
	font-size: 1.1rem
}

h5,
.h5 {
	font-size: 1rem
}

h6,
.h6 {
	font-size: 1rem
}

.lead {
	font-size: 1.25rem;
	font-weight: 300
}

.display-1 {
	font-size: 6rem;
	font-weight: 300;
	line-height: 1.2
}

.display-2 {
	font-size: 5.5rem;
	font-weight: 300;
	line-height: 1.2
}

.display-3 {
	font-size: 4.5rem;
	font-weight: 300;
	line-height: 1.2
}

.display-4 {
	font-size: 3.5rem;
	font-weight: 300;
	line-height: 1.2
}

hr {
	border-top: 1px solid rgba(0, 0, 0, 0.1)
}

small,
.small {
	font-size: 80%;
	font-weight: 400
}

mark,
.mark {
	padding: .2em;
	background-color: #fcf8e3
}

.blockquote {
	padding: .5rem 1rem;
	margin-bottom: 2rem;
	font-size: 1.25rem;
	border-left: 5px solid #5cba3c
}

.blockquote-footer {
	color: #868e96;
}

.blockquote-footer::before {
	content: "\2014 \00A0";
}

.page-item:first-child .page-link {
	border-top-left-radius: .25rem;
	border-bottom-left-radius: .25rem;
}

.page-item:last-child .page-link {
	border-top-right-radius: .25rem;
	border-bottom-right-radius: .25rem;
}

.page-item.active .page-link {
	color: #fff;
	background-color: #5cba3c;
	border-color: #5cba3c
}

.page-item.disabled .page-link {
	color: #868e96;
	background-color: #fff;
	border-color: #ddd
}

.page-link {
	padding: .5rem .75rem;
	line-height: 1.25;
	color: #5cba3c;
	background-color: #fff;
	border: 1px solid #ddd
}

.page-link:focus,
.page-link:hover {
	color: #5cba3c;
	text-decoration: none;
	background-color: #e9ecef;
	border-color: #ddd
}

.pagination-lg .page-link {
	padding: .75rem 1.5rem;
	font-size: 1.25rem;
	line-height: 1.5
}

.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: .3rem;
	border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: .3rem;
	border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
	padding: .25rem .5rem;
	font-size: .875rem;
	line-height: 1.5
}

.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: .2rem;
	border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: .2rem;
	border-bottom-right-radius: .2rem
}

.bg-primary {
	background-color: #5cba3c !important
}

a.bg-primary:focus,
a.bg-primary:hover {
	background-color: #5cba3c !important
}

.bg-secondary {
	background-color: #868e96 !important
}

a.bg-secondary:focus,
a.bg-secondary:hover {
	background-color: #6c757d !important
}

.bg-success {
	background-color: #28a745 !important
}

a.bg-success:focus,
a.bg-success:hover {
	background-color: #1e7e34 !important
}

.bg-info {
	background-color: #17a2b8 !important
}

a.bg-info:focus,
a.bg-info:hover {
	background-color: #117a8b !important
}

.bg-warning {
	background-color: #ffc107 !important
}

a.bg-warning:focus,
a.bg-warning:hover {
	background-color: #d39e00 !important
}

.bg-danger {
	background-color: #dc3545 !important
}

a.bg-danger:focus,
a.bg-danger:hover {
	background-color: #bd2130 !important
}

.bg-light {
	background-color: #f8f9fa !important
}

a.bg-light:focus,
a.bg-light:hover {
	background-color: #dae0e5 !important
}

.bg-dark {
	background-color: #343a40 !important
}

a.bg-dark:focus,
a.bg-dark:hover {
	background-color: #1d2124 !important
}

.border-primary {
	border-color: #5cba3c !important
}

.border-secondary {
	border-color: #868e96 !important
}

.border-success {
	border-color: #28a745 !important
}

.border-info {
	border-color: #17a2b8 !important
}

.border-warning {
	border-color: #ffc107 !important
}

.border-danger {
	border-color: #dc3545 !important
}

.border-light {
	border-color: #f8f9fa !important
}

.border-dark {
	border-color: #343a40 !important
}

.text-primary {
	color: #5cba3c !important
}

a.text-primary:focus,
a.text-primary:hover {
	color: #5cba3c !important
}

.text-secondary {
	color: #868e96 !important
}

a.text-secondary:focus,
a.text-secondary:hover {
	color: #6c757d !important
}

.text-success {
	color: #28a745 !important
}

a.text-success:focus,
a.text-success:hover {
	color: #1e7e34 !important
}

.text-info {
	color: #17a2b8 !important
}

a.text-info:focus,
a.text-info:hover {
	color: #117a8b !important
}

.text-warning {
	color: #ffc107 !important
}

a.text-warning:focus,
a.text-warning:hover {
	color: #d39e00 !important
}

.text-danger {
	color: #dc3545 !important
}

a.text-danger:focus,
a.text-danger:hover {
	color: #bd2130 !important
}

.text-light {
	color: #f8f9fa !important
}

a.text-light:focus,
a.text-light:hover {
	color: #dae0e5 !important
}

.text-dark {
	color: #343a40 !important
}

a.text-dark:focus,
a.text-dark:hover {
	color: #1d2124 !important
}

.badge-primary {
	color: color-yiq(#5cba3c);
	background-color: #5cba3c
}

.badge-primary[href]:focus,
.badge-primary[href]:hover {
	color: color-yiq(#5cba3c);
	text-decoration: none;
	background-color: #5cba3c
}

.badge-secondary {
	color: color-yiq(#868e96);
	background-color: #868e96
}

.badge-secondary[href]:focus,
.badge-secondary[href]:hover {
	color: color-yiq(#868e96);
	text-decoration: none;
	background-color: #6c757d
}

.badge-success {
	color: color-yiq(#28a745);
	background-color: #28a745
}

.badge-success[href]:focus,
.badge-success[href]:hover {
	color: color-yiq(#28a745);
	text-decoration: none;
	background-color: #1e7e34
}

.badge-info {
	color: color-yiq(#17a2b8);
	background-color: #17a2b8
}

.badge-info[href]:focus,
.badge-info[href]:hover {
	color: color-yiq(#17a2b8);
	text-decoration: none;
	background-color: #117a8b
}

.badge-warning {
	color: color-yiq(#ffc107);
	background-color: #ffc107
}

.badge-warning[href]:focus,
.badge-warning[href]:hover {
	color: color-yiq(#ffc107);
	text-decoration: none;
	background-color: #d39e00
}

.badge-danger {
	color: color-yiq(#dc3545);
	background-color: #dc3545
}

.badge-danger[href]:focus,
.badge-danger[href]:hover {
	color: color-yiq(#dc3545);
	text-decoration: none;
	background-color: #bd2130
}

.badge-light {
	color: color-yiq(#f8f9fa);
	background-color: #f8f9fa
}

.badge-light[href]:focus,
.badge-light[href]:hover {
	color: color-yiq(#f8f9fa);
	text-decoration: none;
	background-color: #dae0e5
}

.badge-dark {
	color: color-yiq(#343a40);
	background-color: #343a40
}

.badge-dark[href]:focus,
.badge-dark[href]:hover {
	color: color-yiq(#343a40);
	text-decoration: none;
	background-color: #1d2124
}

.form-control {
	padding: .625rem 1.5rem;
	font-size: 1rem;
	line-height: 1.5;
	color: #495057;
	background-color: none;
	border: 1px solid #ced4da;
	border-radius: 40px;
	-webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s
}

.form-control:focus {
	color: #495057;
	background-color: #fff;
	border-color: #c7d7f6;
	outline: none;
	-webkit-box-shadow: 0 0 0 0.2rem rgb(91, 167, 57);
	box-shadow: 0 0 0 0.2rem rgb(91, 167, 57)
}

.form-control::-webkit-input-placeholder {
	color: #868e96
}

.form-control:-ms-input-placeholder {
	color: #868e96
}

.form-control::placeholder {
	color: #868e96
}

.form-control:disabled,
.form-control[readonly] {
	background-color: #e9ecef
}

select.form-control:not([size]):not([multiple]) {
	height: calc(2.75rem + 2px)
}

select.form-control:focus::-ms-value {
	color: #495057;
	background-color: #fff
}

.form-control-sm {
	padding: .4rem 1.2rem;
	font-size: .875rem;
	line-height: 1.5;
	border-radius: .2rem
}

select.form-control-sm:not([size]):not([multiple]) {
	height: calc(2.1125rem + 2px)
}

.form-control-lg {
	padding: .8rem 1.8rem;
	font-size: 1.25rem;
	line-height: 1.5;
	border-radius: .3rem
}

select.form-control-lg:not([size]):not([multiple]) {
	height: calc(3.475rem + 2px)
}

.valid-feedback {
	display: none;
	margin-top: .25rem;
	font-size: .875rem;
	color: #28a745
}

.valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	width: 250px;
	padding: .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	line-height: 1;
	color: #fff;
	background-color: rgba(40, 167, 69, 0.8);
	border-radius: .2rem
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
	border-color: #28a745
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
	-webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
	box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25)
}

.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip,
.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip {
	display: block
}

.was-validated .form-check-input:valid+.form-check-label,
.form-check-input.is-valid+.form-check-label {
	color: #28a745
}

.was-validated .custom-control-input:valid~.custom-control-indicator,
.custom-control-input.is-valid~.custom-control-indicator {
	background-color: rgba(40, 167, 69, 0.25)
}

.was-validated .custom-control-input:valid~.custom-control-description,
.custom-control-input.is-valid~.custom-control-description {
	color: #28a745
}

.was-validated .custom-file-input:valid~.custom-file-control,
.custom-file-input.is-valid~.custom-file-control {
	border-color: #28a745
}

.was-validated .custom-file-input:valid~.custom-file-control::before,
.custom-file-input.is-valid~.custom-file-control::before {
	border-color: inherit
}

.was-validated .custom-file-input:valid:focus,
.custom-file-input.is-valid:focus {
	-webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
	box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25)
}

.invalid-feedback {
	display: none;
	margin-top: .25rem;
	font-size: .875rem;
	color: #dc3545
}

.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	width: 250px;
	padding: .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	line-height: 1;
	color: #fff;
	background-color: rgba(220, 53, 69, 0.8);
	border-radius: .2rem
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
	border-color: #dc3545
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
	-webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
	box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25)
}

.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip {
	display: block
}

.was-validated .form-check-input:invalid+.form-check-label,
.form-check-input.is-invalid+.form-check-label {
	color: #dc3545
}

.was-validated .custom-control-input:invalid~.custom-control-indicator,
.custom-control-input.is-invalid~.custom-control-indicator {
	background-color: rgba(220, 53, 69, 0.25)
}

.was-validated .custom-control-input:invalid~.custom-control-description,
.custom-control-input.is-invalid~.custom-control-description {
	color: #dc3545
}

.was-validated .custom-file-input:invalid~.custom-file-control,
.custom-file-input.is-invalid~.custom-file-control {
	border-color: #dc3545
}

.was-validated .custom-file-input:invalid~.custom-file-control::before,
.custom-file-input.is-invalid~.custom-file-control::before {
	border-color: inherit
}

.was-validated .custom-file-input:invalid:focus,
.custom-file-input.is-invalid:focus {
	-webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
	box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25)
}
/* Custom */
p.template-text > span.light-grey {
	color: #666;
}
p.industries-template-text, span.industries-template-text {
	display: block;
  font-weight: 300;
  line-height: 1.7rem;
  color: #666;
}

span.industries-template-text > i.fa-hand-o-right {
	margin-right: 10px;
}
.bg-black-1 > div.container > header > h2 {
	color: #666 !important;
}
.bg-black-3 > div.container > header > h2 {
	color: #666 !important;
}
.bg-black-5 > div.container > header > h2 {
	color: #666 !important;
}
.text-highlight {
	font-size: 1rem;
	color: #5cba3c;
}
.text-center {
	text-align: center;
}