@charset "UTF-8";
@font-face {
  font-family: "moneyequations";
  src:url(/fonts/moneyequations.eot);
  src:url(/fonts/moneyequationsAJ.eot?#iefix) format("embedded-opentype"),
    url(/fonts/moneyequations.woff) format("woff"),
    url(/fonts/moneyequations.ttf) format("truetype"),
    url(/img/moneyequations.svg#moneyequations) format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "moneyequations" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  /* speak: none; */
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "moneyequations" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  /* speak: none; */
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-envelope-1:before {
  content: "\e12e";
}
.icon-smart-phone-2:before {
  content: "\e12d";
}
