.loading {
  height: 100%;
  width: 100%;
  z-index: 99;
}
.loading-section {
  text-align: center;
}
@keyframes ldio-6sacx4qu4ph {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
.ldio-6sacx4qu4ph > div > div {
  transform-origin: 100px 100px;
  animation: ldio-6sacx4qu4ph 3.0303030303030303s linear infinite;
  opacity: 0.8
}
.ldio-6sacx4qu4ph > div > div > div {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 70px;
  height: 70px;
  border-radius: 70px 0 0 0;
  transform-origin: 100px 100px
}.ldio-6sacx4qu4ph > div div:nth-child(1) {
  animation-duration: 0.7575757575757576s
}
.ldio-6sacx4qu4ph > div div:nth-child(1) > div {
  background: #5cba3c;
  transform: rotate(0deg);
}.ldio-6sacx4qu4ph > div div:nth-child(2) {
  animation-duration: 1.0101010101010102s
}
.ldio-6sacx4qu4ph > div div:nth-child(2) > div {
  background: #5cba3c;
  transform: rotate(0deg);
}.ldio-6sacx4qu4ph > div div:nth-child(3) {
  animation-duration: 1.5151515151515151s
}
.ldio-6sacx4qu4ph > div div:nth-child(3) > div {
  background: #5cba3c;
  transform: rotate(0deg);
}.ldio-6sacx4qu4ph > div div:nth-child(4) {
  animation-duration: 3.0303030303030303s
}
.ldio-6sacx4qu4ph > div div:nth-child(4) > div {
  background: #5cba3c;
  transform: rotate(0deg);
}
.loadingio-spinner-wedges-tvsks0bde39 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-6sacx4qu4ph {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-6sacx4qu4ph div { box-sizing: content-box; }
/* generated by https://loading.io/ */